<template>
  <div :class="['text-field', dark ? 'dark' : null]">
    <label v-if="label">{{ label }}</label>
    <input
      :type="type"
      v-model="value"
      :class="{ invalid: invalid }"
      @keyup.enter="submit"
    />
  </div>
</template>

<script>
export default {
  name: "TextField",

  props: {
    modelValue: {
      type: [String, Number, null],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    validation: {
      type: Object,
      default: null,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    invalid: false,
  }),

  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },

  watch: {
    validation: {
      handler(value) {
        this.invalid = value.$dirty && value.$error;
      },
      deep: true,
    },
  },

  methods: {
    submit(event) {
      event.target.blur();
      this.$emit("action");
    },
  },
};
</script>

<style lang="scss" scoped>
.text-field {
  @apply mb-3;
  @apply text-gray-700;

  label {
    @apply block;
    @apply mb-1;
  }

  input {
    @apply border;
    @apply border-gray-300;
    @apply p-2;
    @apply rounded-lg;
    @apply w-full;

    &:focus,
    &:active {
      @apply outline-none;
    }

    &.invalid {
      @apply bg-red-600;
      @apply text-white;
    }
  }

  &.dark {
    label {
      @apply text-white;
    }

    input {
      @apply border-white;
    }
  }
}
</style>
